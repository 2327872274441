.content-area:has(.t100-home-page) + .main-footer {
	.main-footer__inner {
		@media (min-width: 1400px) {
			max-width: 1300px;
		}

		@media (min-width: 1700px) {
			max-width: 1500px;
		}

		@media (min-width: 1900px) {
			max-width: 1700px;
		}
	}
}

header.t100-header {
	min-height: 228px;
	text-align: center;
	background-size: 100%;
	background-repeat: no-repeat;
	background-image: url("./assets/t100-home-page-background.png");
	position: relative;
	padding: 45px 0 20px;

	::selection {
		background-color: #36ffff;
		color: white;
	}

	h1 {
		z-index: 2;
		color: white;
		font-size: 14px;
		font-weight: 900;
		text-transform: uppercase;
		font-family: Transducer-Extended, aktiv-grotesk;

		img {
			display: block;
			width: 180px;
			margin: 0 auto 35px;
		}

		@media (min-width: 992px) {
			font-size: 17px;
		}
	}

	p {
		margin-top: 8px;
		color: white;
		z-index: 2;
		font-size: 14px;
		padding-inline: 30px;
		font-family: aktiv-grotesk;
	}

	&::before {
		background-color: #04152d;
		content: "";
		display: block;
		position: absolute;
		height: 100%;
		top: 0;
		width: 100vw;
		transform: translateX(-50%);
		left: 50%;
		z-index: -1;
		opacity: 0.45;
	}

	&::after {
		background-image: url("./assets/t100-city-background.png");
		content: "";
		display: block;
		position: absolute;
		background-repeat: no-repeat;
		height: 100%;
		top: 0;
		width: 100vw;
		transform: translateX(-50%);
		left: 50%;
		background-size: cover;
		background-position: center;
		z-index: -2;
		box-shadow: inset 0px -25px 29px 0px #04152d, inset 0px 25px 28px 0px #04152d;
	}

	&.final-published {
		&::after {
			background-image: url("./assets/t100-page-header-bg-2025.jpg");
		}
	}

	@media (min-width: 768px) {
		min-height: 425px;
		padding: 75px 0 40px;

		h1 {
			font-size: 22px;

			img {
				width: 300px;
				margin-bottom: 45px;
			}
		}

		p {
			font-size: 16px;
		}

		&::before {
			opacity: 0.525;
		}

		&::after {
			box-shadow: inset 0px -75px 89px 0px #04152d, inset 0px 75px 89px 0px #04152d;

			@media (min-width: 992px) {
				box-shadow: inset 0px -85px 68px 0px #04152d, inset 0px 80px 108px 0px #04152d;
			}
		}
	}
}

.athlete-pic:has(.nopic) {
	display: none !important;
}

.t100-home-page {
	overflow-x: hidden;
	position: relative;
	background-color: #04152d;
	min-height: calc(100dvh - 52px);
	font-family: Transducer-Extended, aktiv-grotesk;

	ol > button {
		padding: 0;
		background: transparent;
		width: 100%;
		border: none;
	}

	@media (min-width: 992px) {
		&::before,
		&::after {
			content: "";
			display: block;
			height: 2800px;
			z-index: 3;
			width: 70px;
			position: absolute;
			right: -100px;
			bottom: 0;
			box-shadow: 0 83px 110px 100px #04152d;

			@media (min-width: 1300px) {
				width: 100px;
			}
		}

		&::before {
			left: -120px;
			right: unset;
		}
	}

	.hide-mobile {
		display: none;
	}

	.hide-desktop {
		margin-right: 2px;
	}

	h2 {
		color: white;
		font-size: 19px;
		grid-column: 1 / -1;
		padding-bottom: 8px;
		text-transform: uppercase;

		& + p {
			@media (min-width: 768px) {
				max-width: 600px;
			}
		}
	}

	h3 {
		font-size: 13px;
		color: #36ffff;
		text-transform: uppercase;

		&:not(:nth-child(3)) {
			margin-top: 72px;

			@media (min-width: 992px) {
				margin-top: 26px;
			}
		}
	}

	.container {
		padding-top: 24px;

		@media (min-width: 1400px) {
			max-width: 1300px;
		}

		@media (min-width: 1700px) {
			max-width: 1500px;
		}

		@media (min-width: 1900px) {
			max-width: 1700px;
		}
	}

	&__upcoming-races {
		ol {
			gap: 19px;
			padding: 0;
			display: grid;
			margin-bottom: 0;
			list-style: none;
			padding-top: 16px;
			grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
		}

		.upcoming-race {
			height: 100%;
			min-width: unset;
			color: #36ffff;
			min-height: 72px;
			padding: 12px 13px;
			border-radius: 16px;
			border-width: 1px;
			border-color: transparent;
			transition: all 350ms;
			outline: 1px solid #36ffff;

			&__name:first-child {
				font-size: 13px !important;
				line-height: 17px !important;
			}

			&__date:last-child {
				margin-top: 4px;
				color: #36ffff !important;
				font-family: aktiv-grotesk;
			}

			&:hover {
				box-shadow: 0px 0px 10px #36ffff;
				border-color: #36ffff;
				background-color: transparent !important;

				.upcoming-race__name,
				.upcoming-race__date {
					color: #36ffff !important;
				}
			}
		}

		.no-upcoming-races {
			color: white;
			padding-top: 12px;
			font-family: aktiv-grotesk;
		}
	}

	&__current-standings {
		display: grid;
		column-gap: 4px;
		margin-top: 59px;
		padding-bottom: 64px;
		grid-template-columns: repeat(2, 1fr);

		h2:has(+ p) {
			padding-bottom: 10px !important;
		}

		@media (min-width: 768px) and (max-width: 992px) {
			h3 {
				font-size: 13px !important;
			}
		}

		& > p {
			color: white;
			font-size: 11px;
			grid-row: 2 / 3;
			margin-bottom: 18px;
			grid-column: 1 / 3;
			font-family: aktiv-grotesk;

			@media (min-width: 992px) {
				max-width: 262px !important;
				margin-top: 4px;
				font-size: 13px;
			}
		}

		@media (max-width: 375px) {
			display: flex !important;
			flex-direction: column;

			h3:not(:nth-child(3)) {
				margin-top: 32px;
			}

			ol:last-of-type {
				grid-column: unset !important;
			}
		}

		ol {
			@media (min-width: 992px) {
				position: relative;
			}
		}

		.swiper-slide {
			opacity: 0;
			min-height: 320px;
			z-index: 6;
			box-shadow: 0px 0px 10px #36ffff;
			max-width: 468px;
			position: absolute !important;
			transition: opacity 350ms;

			.close-popover {
				background: transparent;
				padding: 0;
				border: none;
				position: absolute;
				top: 13px;
				right: 12px;

				img {
					width: 34px;
					height: 34px;
				}

				transition: opacity 350ms;

				&:hover {
					opacity: 0.7;
				}
			}

			&.show {
				display: grid !important;
				opacity: 1;
			}

			@media (max-width: 400px) {
				width: 90vw !important;
				grid-template-columns: 100px auto !important;
			}
		}

		> a {
			color: white;
			font-size: 8px;
			grid-row: 5 / 6;
			padding-top: 18px;
			font-weight: bold;
			justify-self: end;
			text-transform: uppercase;
			transition: color 350ms !important;

			@media (max-width: 375px) {
				margin-bottom: 40px;
			}

			&:hover {
				color: #36ffff;
			}

			i {
				margin-left: 4px;
			}
		}

		h2 {
			padding-bottom: 26px;
		}

		h3 {
			grid-row: 3 / 4;
			margin-top: 0px !important;
			padding-bottom: 24px;
		}

		ol {
			margin: 0;
			padding: 0;
			list-style: none;
			grid-column: 1 / 2;
			grid-row: 4 / 5;

			&:last-of-type {
				grid-column: 2 / 3;
			}
		}

		.current-standing {
			height: 50px;
			display: grid;
			margin-bottom: 7px;
			position: relative;
			grid-template-rows: auto 20px;
			grid-template-columns: 40px 50px auto auto auto;

			&__points {
				color: black;
				width: 100%;
				max-width: 70px;
				grid-column: 2 / 3;
				font-weight: bold;
				position: relative;

				&::before {
					top: 0;
					z-index: -1;
					content: "";
					width: 100%;
					height: 100%;
					display: block;
					position: absolute;
					transform: skewX(-30deg);
					background-color: #36ffff;
				}
			}

			&__points,
			&__tier {
				margin-left: -4px;
				z-index: 4;
				display: flex;
				font-size: 7px;
				align-items: center;
				justify-content: center;
			}

			&__tier {
				width: 72px;
				color: #fa1cb8;
				font-weight: bold;
				grid-column: 3 / 4;
				position: relative;
				text-transform: uppercase;

				&::before {
					top: 0;
					z-index: -1;
					content: "";
					width: 100%;
					height: 100%;
					display: block;
					position: absolute;
					transform: skewX(-30deg);
					background-color: #04152d;
					border: 2px solid #fa1cb8;
				}

				&--hot-shot {
					color: #d3fe35;

					&::before {
						border: 2px solid #d3fe35;
					}
				}
			}

			&__rank {
				position: absolute;
				font-size: 9px;
				color: #36ffff;
				padding-top: 7px;
				font-weight: bold;
				top: 0;
			}

			&__image {
				grid-row: 1 / -1;
				grid-column: 1 / 2;
				position: relative;
				display: flex;
				align-items: flex-end;

				&::before {
					content: "";
					display: block;
					right: -17px;
					background-size: cover;
					position: absolute;
					width: 137%;
					height: 100%;
					background-image: url("./assets/t100-home-page-athlete-background.png");

					@media (min-width: 992px) {
						width: 127%;
					}
				}

				.athlete-pic {
					width: 40px;
					height: 40px;
					background: transparent;
				}
			}

			&__country {
				top: 0px;
				left: 34px;
				z-index: 4;
				grid-row: 1 / 2;
				grid-column: 1 / 2;
				position: absolute;

				span {
					width: 18px;
					height: 11px;
					mask-size: 100%;
					mask-image: url("./assets/flag-mask.svg");
				}
			}

			&__name {
				z-index: 2;
				height: 34px;
				font-size: 9px;
				line-height: 9px;
				display: flex;
				text-align: left;
				align-items: center;
				grid-row: 1 / 2;
				margin-left: 17px;
				color: #000000;
				font-weight: bold;
				position: relative;
				margin-bottom: -6px;
				grid-column: 2 / -1;
				text-transform: uppercase;
				padding-right: 4px;

				span {
					padding-left: 4px;
				}

				&::before {
					top: 0;
					left: -8px;
					z-index: -1;
					content: "";
					width: 100%;
					height: 100%;
					display: block;
					position: absolute;
					transform: skewX(-30deg);
					background-color: white;
				}
			}
		}
		.swiper-slide {
			height: auto;
			margin-bottom: 18px;
			grid-template-rows: min-content min-content auto min-content min-content;
			background: transparent linear-gradient(180deg, #25a59b 0%, #04152d 58%) 0% 0% no-repeat padding-box;
			border: 1px solid #36ffff;
			border-radius: 16px;
			column-gap: 13px;
			padding-inline: 16px 14px;
			padding-block: 14px 26px;
			display: grid;
			color: white;
			font-weight: bold;
			align-items: top;
			font-family: Transducer-Extended, aktiv-grotesk;
			grid-template-columns: 120px auto;
			width: 92% !important;
			left: 20px;

			&__rank {
				font-size: 32px;
				color: #36ffff;
				line-height: 31px;
				grid-column: 1 / 2;
				grid-row: 1 / 2;
				justify-self: end;
			}

			&__country {
				height: min-content;
				display: flex;
				font-size: 11px;
				margin-top: 13px;
				grid-column: 2 / 3;
				grid-row: 2 / 3;
				align-items: center;
				font-weight: normal;
				font-family: aktiv-grotesk;

				span {
					width: 32px;
					height: 18px;
					mask-size: 100%;
					mask-image: url("./assets/flag-mask.svg");
				}
			}

			&__name {
				height: 47px;
				font-size: 12px;
				color: white;
				display: flex;
				flex-direction: column;
				text-transform: uppercase;
				grid-column: 2 / 3;
				grid-row: 1 / 2;

				span {
					&:first-child {
						font-size: 12px;
					}

					&:last-child {
						margin-top: -1px;
						font-size: 16px;
						line-height: 15px;
						max-width: 82%;
						text-overflow: ellipsis;
						overflow: hidden;
					}
				}
			}

			a {
				gap: 4px;
				min-width: max-content;
				color: white;
				display: flex;
				grid-column: 2 / 3;
				font-size: 10px;
				align-items: center;
				margin-top: 8px;
				text-transform: uppercase;
				transition: color 350ms;

				&:hover {
					color: #36ffff;
				}
			}

			&__bio-short {
				margin-top: 6px;
				font-weight: normal;
				font-family: aktiv-grotesk;
				font-size: 13px;
				grid-column: 2 / 3;
				line-height: 16px;

				* {
					font-weight: normal;
					font-family: aktiv-grotesk;
					line-height: 16px !important;
					font-size: 13px !important;
					margin-bottom: 0px;
				}
			}

			&__image {
				grid-row: 2 / 5;
				grid-column: 1 / 2;
				position: relative;

				.athlete-pic {
					background: none;
					width: 120px;
					height: 120px;

					@media (max-width: 500px) {
						width: 120px !important;
						height: 120px !important;
					}
				}

				img {
					mask-position: bottom;
					mask-repeat: no-repeat;
					mask-image: linear-gradient(180deg, #04172e 31%, rgba(4, 21, 45, 0) 100%);
				}

				.athlete-pic-group {
					margin-top: 0;
					position: absolute;
					bottom: 0;
				}
			}

			footer {
				display: grid;
				align-items: center;
				grid-column: 1 / -1;
				margin-top: 32px;
				padding-inline: 16px 14px;
				grid-template-columns: 5fr repeat(3, 4fr);

				> span {
					z-index: 1;
					display: flex;
					align-items: center;
					justify-content: center;
					height: 38px;
					font-size: 9px;
					color: #36ffff;
					position: relative;

					@media (min-width: 400px) and (max-width: 992px) {
						font-size: 13px;
					}

					@media (min-width: 600px) {
						&.rank {
							gap: 0px;
							flex-direction: column !important;
						}
					}

					&.rank {
						display: flex;
						align-items: center;
						padding-top: 1px;
						flex-direction: column;
						margin-left: -1px;

						img {
							max-height: 15px;
							max-width: 17px;

							@media (max-width: 768px) {
								margin-left: 9px;
							}
						}
					}

					&:first-child {
						color: black;

						&::before {
							background-color: #36ffff;
						}
					}

					&::before {
						top: 0;
						z-index: -1;
						content: "";
						width: 100%;
						border: 1px solid #36ffff;
						height: 100%;
						display: block;
						position: absolute;
						transform: skewX(-30deg);
					}
				}
			}
		}
	}

	&__contracted-athletes,
	&__hot-shots,
	&__wildcards {
		margin-bottom: 62px;

		@media (min-width: 992px) {
			margin-bottom: 72px;
		}

		> p {
			color: white;
			font-size: 11px;
			margin-bottom: 22px;
			font-family: aktiv-grotesk;

			> a {
				color: #00ffff;
				transition: all 350ms;
				text-decoration: underline;

				&:hover {
					opacity: 0.3;
					color: #00ffff !important;
				}
			}
		}

		h3 {
			margin-bottom: 13px;

			@media (min-width: 992px) {
				margin-bottom: 16px;
			}
		}

		.swiper-slide {
			height: auto;
			margin-bottom: 18px;
			grid-template-rows: min-content min-content auto min-content min-content;
			background: transparent linear-gradient(180deg, #25a59b 0%, #04152d 58%) 0% 0% no-repeat padding-box;
			border: 1px solid #36ffff;
			border-radius: 16px;
			column-gap: 13px;
			padding-inline: 16px 14px;
			padding-block: 14px 26px;
			display: grid;
			color: white;
			font-weight: bold;
			align-items: top;
			font-family: Transducer-Extended, aktiv-grotesk;
			grid-template-columns: 100px auto;

			@media (max-width: 400px) {
				padding-left: 9px;

				&__bio-short {
					font-size: 11px !important;

					*,
					> p {
						font-size: 11px !important;
					}
				}
			}

			@media (min-width: 375px) {
				grid-template-columns: 130px auto;
			}

			&__rank {
				font-size: 32px;
				color: #36ffff;
				line-height: 31px;
				grid-column: 1 / 2;
				grid-row: 1 / 2;
				justify-self: end;
			}

			&__country {
				height: min-content;
				display: flex;
				font-size: 11px;
				margin-top: 13px;
				grid-column: 2 / 3;
				grid-row: 2 / 3;
				align-items: center;
				font-weight: normal;
				font-family: aktiv-grotesk;

				span {
					width: 32px;
					height: 18px;
					mask-size: 100%;
					mask-image: url("./assets/flag-mask.svg");
				}
			}

			&__name {
				font-size: 12px;
				color: white;
				display: flex;
				flex-direction: column;
				text-transform: uppercase;
				grid-column: 2 / 3;
				grid-row: 1 / 2;

				span {
					&:first-child {
						font-size: 12px;
					}

					&:last-child {
						margin-top: -1px;
						font-size: 16px;
						line-height: 15px;
					}
				}
			}

			a {
				gap: 4px;
				min-width: max-content;
				color: white;
				display: flex;
				grid-column: 2 / 3;
				font-size: 10px;
				align-items: center;
				margin-top: 8px;
				text-transform: uppercase;
				transition: color 350ms;
				margin-bottom: 12px;

				&:hover {
					color: #36ffff;
				}
			}

			&__bio-short {
				margin-top: 6px;
				font-weight: normal;
				font-family: aktiv-grotesk;
				font-size: 13px;
				grid-column: 2 / 3;
				line-height: 16px;
				text-overflow: ellipsis;

				* {
					font-weight: normal;
					font-family: aktiv-grotesk;
					line-height: 16px !important;
					font-size: 13px !important;
					margin-bottom: 0px;
				}
			}

			&__image {
				grid-row: 2 / 5;
				grid-column: 1 / 2;
				position: relative;
				display: flex;
				align-items: flex-end;

				.athlete-pic {
					background: none;
					width: 130px;
					height: 130px;
				}

				img {
					mask-position: bottom;
					mask-repeat: no-repeat;
					mask-image: linear-gradient(180deg, #04172e 31%, rgba(4, 21, 45, 0) 100%);
				}

				.athlete-pic-group {
					margin-top: 0;
				}
			}

			footer {
				display: grid;
				align-items: center;
				grid-column: 1 / -1;
				margin-top: 32px;
				height: min-content;
				padding-inline: 16px 14px;
				grid-template-columns: 5fr repeat(3, 4fr);

				> span {
					z-index: 1;
					display: flex;
					align-items: center;
					justify-content: center;
					height: 38px;
					font-size: 9px;
					color: #36ffff;
					position: relative;

					@media (min-width: 400px) and (max-width: 992px) {
						font-size: 11px;
					}

					&.rank {
						display: flex;
						align-items: center;
						padding-top: 1px;
						flex-direction: column;
						margin-left: -1px;

						img {
							max-height: 15px;
							max-width: 17px;

							@media (max-width: 768px) {
								margin-left: 9px;
							}
						}
					}

					&:first-child {
						color: black;

						&::before {
							background-color: #36ffff;
						}
					}

					&::before {
						top: 0;
						z-index: -1;
						content: "";
						width: 100%;
						border: 1px solid #36ffff;
						height: 100%;
						display: block;
						position: absolute;
						transform: skewX(-30deg);
					}
				}
			}
		}

		.swiper-buttons {
			display: flex;
			position: relative;
			margin-bottom: 42px;
			justify-content: space-between;

			@media (min-width: 992px) {
				margin-bottom: 0;
			}
		}

		.swiper-button-next,
		.swiper-button-prev {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 28px;
			height: 28px;
			z-index: 0 !important;
			border-radius: 50%;
			border: none;
			background-color: #30787e;
			transition: all 350ms;

			&::after {
				content: unset !important;
			}

			@media (max-width: 992px) {
				margin-top: 0px;
			}

			@media (min-width: 992px) {
				&:hover {
					background-color: rgba(48, 119, 126, 0.54);
					transform: scale(1.1) translateY(-50%);

					i {
						transform: scale(1);
					}
				}
			}

			i {
				transition: all 350ms;
				color: white;
			}

			&[disabled] {
				opacity: 0.25;
			}
		}

		.swiper-button-prev {
			i {
				margin-right: 2px;
			}
		}

		.swiper-button-next {
			i {
				margin-left: 2px;
			}
		}
	}

	&__hot-shots,
	&__wildcards {
		.swiper-slide {
			background: transparent linear-gradient(181deg, #859f29 0%, #04152d 58%) 0% 0% no-repeat padding-box;
			border: 1px solid #d3fe35;
			position: relative;

			&__rank {
				color: #d3fe35;
			}

			&::before {
				top: 84px;
				left: -42px;
				font-size: 17px;
				color: #d3fe35;
				content: "Hot Shot";
				position: absolute;
				transform: rotate(-90deg);
				text-transform: uppercase;

				@media (min-width: 992px) {
					top: 78px;
					left: -45px;
					font-size: 19px;
				}
			}
		}
	}

	&__wildcards {
		margin-bottom: 144px;

		.swiper-wrapper > .swiper-slide {
			> .swiper-slide__image {
				.athlete-pic {
					width: 180px !important;
					height: 180px !important;
				}
			}
			> .swiper-slide__country {
				grid-column: 1 / -1 !important;
			}

			> .swiper-slide {
				/* min-height: 300px; */

				.swiper-slide__image {
					grid-row: 1 / -2 !important;
					display: flex;
					align-items: flex-end;
				}
			}

			&:last-child {
				.swiper-slide.visible {
					left: unset !important;
					right: 24px !important;
				}
			}
		}

		.swiper-slide {
			background: transparent linear-gradient(360deg, #04152d 0%, #811e64 58%) 0% 0% no-repeat padding-box;
			border: 1px solid #fa1cb8;
			grid-template-rows: minmax(152px, auto) min-content min-content;
			min-height: 270px;
			padding-bottom: 9px;

			> a {
				margin-bottom: 18px;
			}

			&:not(.swiper-slide--popover) {
				.swiper-slide {
					&__rank {
						color: #fa1cb8;
						grid-column: 2 / 3;
						position: absolute;
						margin-top: 0;
						right: 6px;
					}

					&__image {
						grid-row: 1 / 2;

						.athlete-pic-group {
							left: 15px;
						}

						picture.athlete-pic {
							width: 190px;
							height: 190px;
						}
					}

					&__name {
						grid-column: 1 / 2;
						grid-row: 2 / 3;
						height: unset;
						justify-content: flex-end;
					}

					&__country {
						grid-column: 1 / 2;
						grid-row: 3 / 4;
						margin-bottom: 6px;
						margin-top: 7px !important;
					}
				}

				&:has(.swiper-slide--popover) {
					position: relative;

					&::after {
						content: "";
						display: block;
						min-width: 100%;
						position: absolute;
						top: 0;
						left: 0;
						height: 100%;
						opacity: 0;
						background-color: #04152d;
						z-index: 1;
						border-radius: 16px;
						border: 1px solid #04152d;
						transition: opacity 450ms;
						transform: none;
					}
				}

				&:has(.swiper-slide--popover.visible) {
					border: 1px solid #b11582ba;

					&::after {
						opacity: 0.6;
					}
				}
			}

			&--popover {
				position: absolute;
				bottom: 2px;
				width: min-content;
				left: 20px;
				z-index: 5;
				min-width: 260px;
				padding-bottom: 23px;
				display: none;
				opacity: 0;
				min-height: max-content;
				box-shadow: 0px 0px 10px #fa1cb8;
				transition: opacity 450ms;
				grid-template-rows: min-content max-content auto min-content min-content !important;
				grid-template-columns: 100px auto !important;

				.swiper-slide__name {
					span:last-child {
						max-width: 82%;
						text-overflow: ellipsis;
						overflow: hidden;
					}
				}

				@media (min-width: 400px) {
					width: 320px;
					grid-template-columns: 130px auto !important;
				}

				.close-popover {
					background: transparent;
					padding: 0;
					border: none;
					position: absolute;
					top: 13px;
					right: 12px;
				}

				@media (min-width: 768px) {
					width: 372px !important;

					.close-popover {
						top: 11px;
						transition: opacity 350ms;

						&:hover {
							opacity: 0.7;
						}

						img {
							width: 34px;
							height: 34px;
						}
					}
				}

				footer {
					margin-top: 18px;
				}

				&.visible {
					display: block;
					opacity: 1;
					transform: translate3d(1px, 1px, 1px);
				}

				.swiper-slide {
					&__rank {
						grid-column: 1 / 2 !important;
						grid-row: 1 / 2;
					}

					&__name {
						grid-column: 2 / 3 !important;
						grid-row: 1 / 2 !important;
					}

					&__bio-short {
						margin-top: 8px;
					}

					&__country {
						grid-column: 2 / 3 !important;
						grid-row: 2 / 3 !important;
					}
				}

				span.swiper-slide__image {
					a.athlete-pic-group {
						left: 8px !important;

						@media (min-width: 768px) {
							left: 0 !important;
						}
					}

					picture.athlete-pic {
						width: 100px !important;
						height: 100px !important;

						@media (min-width: 400px) {
							width: 130px !important;
							height: 130px !important;
						}
					}
				}

				@media (min-width: 992px) {
					top: 40px;
					left: 37px;
				}
			}

			&::before {
				content: "Wildcard";
				color: #fa1cb8;
			}
		}
	}

	@media (min-width: 540px) {
	}

	@media (min-width: 768px) {
		main {
			margin-top: 84px;
			height: calc(100dvh - 84px);
		}

		.container {
			padding-top: 14px;
		}

		.hide-mobile {
			display: inline-block;
		}

		.hide-desktop {
			display: none;
		}

		h2 {
			font-size: 23px;
			line-height: 22px;
		}

		&__upcoming-races {
			ol {
				grid-template-columns: repeat(auto-fit, minmax(200px, 200px));
			}

			.upcoming-race {
				max-width: 196px;
				font-size: 16px;
				line-height: 17px;

				&__name:first-child {
					font-size: 16px !important;
					line-height: 17px !important;
				}

				&__date:last-child {
					font-size: 13px !important;
				}
			}
		}

		&__current-standings {
			padding-bottom: 96px;
			column-gap: 32px;
			margin-top: 92px;
			grid-template-columns: repeat(2, 1fr);

			@media (min-width: 1200px) {
				column-gap: 62px;
			}

			h2 {
				grid-column: 1 / 2;
				margin-right: 34px;
				padding-bottom: 24px;
				grid-row: 1 / 2;

				@media (min-width: 992px) {
					max-width: 220px;
				}
			}

			> p {
				grid-row: 2 / 3;
			}

			h2,
			> p {
				grid-column: 1 / 3;
			}

			h3 {
				grid-row: 3 / 4;
				font-size: 20px;
			}

			ol,
			h3,
			> a {
				grid-column: 1 / 2;

				&:last-of-type {
					grid-column: 2 / 3;
				}
			}

			> a {
				font-size: 13px;
				padding-top: 23px;
			}

			ol > button:first-child {
				.current-standing {
					height: 108px;
					margin-top: 18px;
					grid-template-columns: 20px 102px min-content auto auto;

					&__name {
						height: 80px;
						line-height: 22px;
						font-size: 18px;

						@media (min-width: 1200px) {
							font-size: 20px;
						}

						span {
							padding-left: 12px;
						}
					}

					&__rank {
						font-size: 50px;
					}

					&__points {
						margin-left: -34px;
					}

					&__image {
						.athlete-pic {
							width: 136px;
							height: 136px;
						}
					}

					&__country {
						left: 106px;

						span {
							width: 44px;
							height: 24px;
						}
					}
				}
			}
			ol > button:hover {
				.current-standing {
					&__name {
						&::before {
							background: #c9ffff 0% 0% no-repeat padding-box;
							box-shadow: 0px 0px 15px #36ffff;
						}
					}

					&__rank {
						text-shadow: 0px 0px 6px #36fffe;
					}
				}
			}

			.current-standing {
				height: 70px;
				margin-bottom: 11px;
				grid-template-columns: 20px 78px min-content auto auto;

				&__rank {
					font-size: 22px;
					grid-column: 1 / 2;
					position: relative;
					transition: all 350ms;
				}

				&__image {
					grid-column: 2 / 3;

					&::before {
						right: -37px;
					}

					.athlete-pic {
						left: 6px;
						bottom: 4px;
						width: 80px;
						height: 80px;
						position: absolute;
					}
				}

				&__name {
					grid-column: 3 / -1;
					font-size: 10.5px;
					height: 44px;
					line-height: 13px;
					margin-left: 22px;

					span {
						padding-right: 5px;
					}

					&::before {
						transition: all 350ms;
					}

					@media (min-width: 1200px) {
						font-size: 13px;
					}
				}

				&__country {
					left: 84px;
					top: -1px;

					span {
						width: 35px;
						height: 18px;
						margin-right: 0 !important;
					}
				}

				&__points {
					grid-column: 3 / 4;
					height: 30px;
					font-size: 12px;
					min-width: 100px;
					max-width: 100px;
					margin-left: -3px;
				}

				&__tier {
					grid-column: 4 / 5;
					font-size: 12px;
					width: 120px;
					height: 30px;
				}

				&__tier,
				&__points {
					margin-top: -14px;
				}
			}
		}
	}

	@media (min-width: 992px) {
		.swiper-initialized:not(:last-child) {
			margin-bottom: 22px;
		}

		&__current-standings {
			grid-template-columns: max-content repeat(2, 1fr);

			h3 {
				grid-row: 1 / 2;
			}

			h2,
			p {
				grid-column: 1 / 2;
			}

			ol {
				grid-row: 2 / 3;
			}

			a {
				grid-row: 3 / 4 !important;
			}

			ol,
			h3,
			> a {
				grid-column: 2 / 3;

				&:last-of-type {
					grid-column: 3 / 4;
				}
			}
		}

		&__wildcards {
			.swiper-slide {
				transition: all 350ms;
				outline: 1px solid transparent;

				&:not(:has(.swiper-slide--popover.visible)) {
					&:not(.swiper-slide--popover) {
						cursor: pointer;

						&:hover {
							box-shadow: 0px 0px 15px #fa1cb8;
							border-width: 2px;
							outline-width: 0;
						}
					}
				}
			}
		}

		&__contracted-athletes,
		&__hot-shots,
		&__wildcards {
			position: relative;

			.swiper-initialized {
				position: relative;
			}

			.swiper-buttons {
				width: 98%;
				z-index: 5;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				position: absolute;

				button {
					position: absolute;
				}
			}

			.swiper-button-prev {
				left: 0;
			}

			.swiper-button-next {
				right: 0;
			}

			.swiper-button-next,
			.swiper-button-prev {
				width: 40px;
				height: 40px;
				top: 50%;
				transform: translateY(-50%);
				box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.5);
				z-index: 7 !important;

				i {
					font-size: 19px;
				}

				&[disabled] {
					opacity: 0 !important;
					cursor: normal;
				}
			}

			h2 {
				max-width: unset;
			}

			h3 {
				font-size: 20px;
			}

			> p {
				margin-top: 6px;
				font-size: 13px;
			}

			.swiper-slide {
				padding-bottom: 17px;
				padding-top: 20px;
				grid-template-columns: 152px auto;
				transition: all 350ms;

				&__image {
					.athlete-pic {
						width: 152px;
						height: 152px;
					}
				}

				&__name {
					span {
						&:first-child {
							font-size: 14px;
						}

						&:last-child {
							font-size: 19px;
						}
					}
				}

				&__rank {
					margin-top: 3px;
				}

				&__country {
					font-size: 13px;
					margin-top: 6px;
				}

				&__bio-short {
					font-size: 13px;
					margin-top: 6px;
				}

				a {
					font-size: 13px;
				}

				footer {
					margin-top: 0px;
					grid-template-columns: 6fr repeat(3, 5fr);

					> span {
						font-size: 11px;
						height: 30px;

						&.rank {
							gap: 3px;
							padding-top: 0;
							flex-direction: row;

							img {
								max-height: 15px;
								max-width: 20px;
							}
						}
					}
				}
			}
		}
	}

	::selection {
		background-color: #36ffff;
		color: white;
	}
}
