.athlete-share {
	canvas,
	img {
		max-width: 100%;
		max-height: calc(100vh - 300px);
	}

	.select { max-width: 150px; }
}

#athlete-profile-wrapper {
	h2 {
		font-size: 1.2rem;
	}

	h3 {
		font-size: 1rem;
	}

	h4 {
		font-size: 0.7rem;
	}
}

.expand + #athlete-bio-more {
	&::after {
		transform: rotate(-90deg);
		margin-bottom: -8px;
	}
}

#athlete-bio-more {
	font-size: 19px;
	text-transform: initial;
	position: absolute;
	left: 100%;
	padding-left: 16px;
	cursor: pointer;
	bottom: 17px;
	left: 50%;
	transform: translateX(-50%);

	&::after {
		transform: rotate(90deg);
		margin-left: 9px;
		font-size: 16px;
	}
}

#head-to-head-results {
	left: 0;
	z-index: 1;
	flex-shrink: 1;
	display: flex;
	min-width: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
	max-height: 21rem;
	margin-top: 0.3rem;
	flex-direction: column;
	background-color: white;
	border-radius: var(--border-radius);

	--border-radius: 0.25rem;

	> a {
		width: 100%;
		color: var(--text);
		overflow-x: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		padding: 0.25rem 0.5rem;
		transition-duration: 250ms;
		border-radius: var(--border-radius);
		transition-property: color, background-color;

		&.selected {
			background: var(--pto-red);
		}

		&:hover {
			@media (hover: hover) {
				background-color: var(--text);
				color: white;
			}
		}
	}


	&.hide {
		display: none;
	}
}

#athlete-bio {
	padding: 0 16px;
	margin: 0 auto;
	max-width: 700px;
	font-size: 16px;
	max-height: 60px;
	position: relative;

	h2 {
		display: none;
	}

	&:after {
		position: absolute;
		content: "";
		width: 100%;
		height: 50px;
		bottom: 0;
		left: 0;
		background: linear-gradient(rgba(255,255,255,0), 30%, white);
		z-index: 1;
	}

	overflow: hidden;
	text-overflow: ellipsis;

	&.expand {
		max-height: unset;

		&:after {
			content: unset;
		}
	}

}

.profile-results {
	padding-top: 12px;
	padding-inline: 1px;

	h3 {
		font-size: 24px;
		color: black;
		font-weight: 900 !important;
	}

	h4 {
		font-size: 20px;
		margin-top: 2px;
		font-weight: 900;
		padding-left: 8px !important;
	}

	@media (min-width: 768px) {
		padding-top: 40px;

		h3 {
			margin-bottom: 6px;
		}

		h4 {
			padding-left: 12px;
		}
	}
}

.points-dropdown-toggle {
	background-color: transparent;
	border: none;
	padding-right: 0;
	display: inline-flex;
	align-items: center;
	outline: none;
	margin-bottom: -2px;

	i {
		font-size: 18px;
	}

	&:focus {
		outline: none;
	}

}

.points-dropdown {
	display: none;

	&.open {
		display: table-row;
	}

	& > td {
		padding: 0 !important;
		display: table-cell !important;
	}

	table {
		background-color: black;
		padding-block: 10px;
		width: 100%;

		td,
		th {
			font-size: 12px;;
			color: white;
			text-align: left;
			border-top: none;
			padding-top: 0;
			padding-bottom: 2px;
		}

		th {
			width: 32px;
			font-weight: 600;
			padding-left: 11.5px;
			padding-right: 12px;
			display: table-cell !important;
		}

		td {
			height: unset;
		}

		tr {
			&:first-child {
				td,
				th {
					padding-top: 8px !important;
				}
			}

			&:last-child {
				td,
				th {
					padding-bottom: 6px !important;
				}
			}
		}
	}
}

.points-dropdown-toggle,
.points-dropdown {
	@media (min-width: 768px) {
		display: none;
	}
}

.tier-cell--bronze {
	color: #CD7F32;
}

.tier-cell--silver {
	color: #888888;
}

.tier-cell--gold {
	color: #D4AF37;
}

.tier-cell--diamond {
	color: #7298B9;
}

.ranking_details {
	> .h5 {
		margin-block: 10px 20px;
	}

	.combined {
		margin-top: 8px;
		padding-top: 8px;
		border-top: 1px solid var(--border-color);

		.points {
			font-weight: bold;
		}
	}

	.result {
		width: 100%;
		max-width: 700px;
		display: flex;
		justify-content: space-between;

		small {
			font-style: italic;
			margin-bottom:6px;
			color: #595959;
			display:block;
		}

		.combined {
			margin-bottom: 10px;
		}

		.points {
			span {
				position: relative;

				sup {
					position: absolute;
					top: 10px;
					right: -5px;
					font-weight: bold;
				}
			}
		}
	}

}

.athlete-results {
	text-align: center;
	height: 1px;

	th { 
		color: #000;
		font-weight: unset;
		border-top: 0;
		font-size: 12px;
		text-align: left;
		padding-bottom: 3px;

		img {
			max-height: 8px;
			margin-bottom: 3px;
		}

		@media (min-width: 768px) {
			font-size: 13px;
			padding-bottom: 5px;

			&:first-child {
				padding-left: 12px;
			}

			.tooltip-button {
				padding-left: 4px;

				img {
					height: 11px;
					width: 11px;
				}
			}
		}
	}

	th,
	td {
		&.date,
		&.place {
			padding-left: 9.5px;
		}

		.place {
			width: 32px;
			font-weight: 900;
			text-align: center;
			font-family: Lato, sans-serif;
		}

		&.race {
			width: 180px;
			width: auto !important;
		}

		&.tier {
			text-align: left;
			width: 80px;

			@media (max-width: 420px) {
				display: none;
			}
		}

		&.swim,
		&.bike,
		&.run {
			@media (max-width: 768px) {
				display: none;
			}
		}

		&.sof {
			width: 55px;
			text-align: center;
		}

		&.overall {
			width: 78px;
		}

		&.points {
			width: 44px;
			text-align: center;
		}


		@media (max-width: 768px) {
			&.date {
				display: none;
			}

			&.race {
				width: 60px;
			}

			&.t100-points {
				display: none;
			}
		}
	}

	th {
		&.place {
			font-weight: normal;
		}
	}

	td {
		height: 43px;
		text-align: left;

		&.tier {
			font-size: 10px;

			@media (min-width: 992px) {
				font-size: 12px;
			}
		}

		&.race,
		&.overall {
			@media (max-width: 768px) {
				border-right: 1px solid #E0E0E0;
			}
		}

		&.overall,
		&.sof {
			padding-left: 5px;
		}

		&.points {
			background-color: #D10B0C;
			color: white;
			min-width: 43px;
			padding: 0;
			margin: 0;
	
			@media (max-width: 991px) {
				& > div {
					display: flex;
					height: 100%;
					align-items: center;
					flex-direction: column;
					justify-content: center;
	
					& > * {
						flex: 1;
						display: flex;
						align-items: center;
						justify-content: center;
					}
	
					.t100-points {
						background-color: black;
						color: #00FFFF;
						width: 100%;
					}
				}
			}
		}
	}

	@media (min-width: 768px) {
		.date {
			width: 56px;
		}

		.tier {
			width: 68px;
		}

		.tier,
		.race {
			padding-left: 10px;
		}

		.swim,
		.bike,
		.run,
		.overall {
			width: 54px;
		}

		.t100-points,
		.points {
			width: 52px;
		}

		th.points {
			text-align: left;

			&::before {
				content: "PTO ";
			}
		}

		th.place {
			&::before {
				content: "Pos";
			}
		}

		.race {
			padding-right: 4px;
		}

		td.t100-points {
			background-color: #101010;
			color: #00FFFF;

			div {
				display: flex;
				align-items: center;

				justify-content: center;
			}

			&--empty {
				//background-color: #E5E5E5;
			}
		}

		.points {
			.t100-points {
				display: none;
			}
		}

		.swim {
			width: 46px !important;
		}

		.sof {
			text-align: left;
		}

		.ait {
			width: 62px;
		}

		.place {
			width: 40px;
		}

		td {
			&:first-child {
				padding-left: 12px;
			}
		}
	}
}

.ranking-per-season {
	h3 {
		padding-top: 37px;
		padding-bottom: 8px;
	}

	h4 {
		font-size: 20px;
		margin-left: 0 !important;
		padding-top: 2px;
		padding-left: 12px !important;
		padding-bottom: 11px;
	}

	.entry {
		height: 30px;
	}

	.rank {
		text-align: right;
		padding-right: 17px;
	}

	.year {
		b {
			font-weight: normal !important;
		}
	}

	.bar {
		.fill {
			height: 10px !important;	
		}

		.points {
			padding-left: 8px;
		}
	}

	.head {
		padding-bottom: 5px;

		div {
			color: black;
			font-size: 13px !important;
		}
	}

	.year {
		width: 52px;
		text-align: left;
		padding-left: 12px;
	}

	@media (min-width: 768px) {
		h3 {
			padding-top: 0;
		}

		div.py-2 {
			padding-bottom: 0 !important;
		}

		.entry {
			&:last-child {
				padding-bottom: 10px;
			}
		}
	}
}

.ranking_details + .section-bottom {
	.graph-container {
		p.explainer--2022-recalculated {
			text-align: center;
		}
	}
}

.athlete-biography {
	padding-top: 29px;
	position: relative;
	padding-bottom: 42px;
	text-align: left;

	.h5 {
		margin-bottom: 20px !important;
	}
}

.athlete-info {
	.ranks {
		display: flex;
		flex-wrap: wrap;
		max-width: 350px;

		.rank {
			&--world,
			&--nation {
				width: calc(50% - 12px);
			}

			&--nation {
			}

			&--discipline {
				width: calc(33.33% - 12px);
			}
		}
	}

	.equipments {
		.equipment {
			&.empty {
				line-height: 24px;
			}
		}
	}
}
