.content-area:has(.t100-points) {
	min-height: unset !important;
}

.t100-points {
	h2 {
		margin-bottom: 0 !important;
	}

	.pto-world-rankings__filters {
		padding-block: 20px 25px;
		padding-inline: 11px 16px;
		margin-top: 14px;
		margin-bottom: 0;
		column-gap: 36px;

		form {
			grid-template-rows: minmax(0, 1fr);
		}
	}

	.expand-breakdown {
		z-index: 10;
		cursor: pointer;
	}

	.explainer {
		margin-top: 16px !important;
		display: block !important;
		font-family: aktiv-grotesk;
		margin-bottom: 0 !important;

		&::before {
			content: unset !important;
		}

		a {
			padding-left: 2px;
			color: white !important;
		}

		@media (min-width: 768px) {
			margin-top: 40px !important;
		}
	}

	.pto-world-rankings__header {
		margin-top: 44px;
	}

	&-grid {
		width: 100%;
		padding-right: 0;

		td {
			font-family: aktiv-grotesk;
		}

		& > div {
			@media (min-width: 992px) {
				display: none;
			}
		}

		& > p {
			margin-top: 14px;
			font-size: 13px;

			a {
				color: #d10b0c;
				text-decoration: underline;
			}
		}

		ul {
			position: sticky;
			left: 0;
			top: 0;
			list-style: none;
			padding: 0;
			flex-wrap: wrap;
			row-gap: 8px;
			margin: 0;
			padding-block: 20px 12px;
			display: flex;
			align-items: center;
			gap: 12px;
			z-index: 1;

			@media (min-width: 768px) {
				padding-bottom: 34px !important;
			}

			li {
				font-size: 11px;
				display: flex;
				align-items: center;
				gap: 4px;
				font-family: aktiv-grotesk !important;

				&.qualifying-score,
				&.additional-score {
					& > div {
						height: 12px;
						margin-right: 3px;
						margin-bottom: 2px;
						width: 12px;
						display: block;
						background-color: #00ffff;
					}
				}

				#additional-score-tooltip {
					background-color: #02162d !important;
					border-color: #02162d !important;
					color: white;
					max-width: 250px;
					margin-right: 16px;
					font-family: aktiv-grotesk;

					@media (min-width: 670px) {
						top: 18px;
						left: 477px;
					}

					@media (min-width: 768px) {
						top: 18px;
						left: 480px;
					}

					&.hidden {
						display: none !important;
					}

					b {
						max-width: 160px;
						display: block;
					}
				}

				&.additional-score {
					.tooltip-button {
						padding: 0;
						margin-left: 3px;

						img {
							height: 13px;
							margin-bottom: 2px;
						}
					}

					& > div {
						background-color: #095662;
					}
				}
			}

			img {
				height: 10px;
			}
		}

		table {
			position: relative;
			width: 100%;
			text-transform: uppercase;
			background-color: #fff;
			color: #02162d;
			border: 1px solid #e5e5e5;

			thead {
				th {
					padding-inline: 8px;
					height: 42px;
					text-align: center;
					font-family: Transducer, sans-serif;
					font-weight: 600;
					font-size: 9px;
					border-right: 1px solid #e5e5e5;

					&:first-child {
						text-align: left;
						padding-inline: 0;

						div {
							height: 100%;
							display: flex;
							margin-right: -1px !important;
							margin-left: -1px !important;
						}
					}

					img {
						width: 66px;
						margin-left: 6px;
					}

					&.rotate {
						p {
							transform: rotate(-90deg);
							text-align: center;
						}
					}
				}
			}

			tbody th,
			thead th:first-child {
				&:first-child {
					position: sticky;
					position: -webkit-sticky;
					top: 0;
					left: 0;
					background-color: white;

					div {
						border-right: 1px solid #e5e5e5;
						border-left: 1px solid #e5e5e5;
						margin-right: -2px;
						margin-left: -2px;
					}
				}
			}

			tbody {
				th {
					font-size: 10px;
					text-align: left;
					text-transform: uppercase;
					font-weight: 900;
					letter-spacing: 0;
					line-height: 11px;

					div {
						padding-left: 8px;
						padding-right: 8px;
						align-items: flex-start !important;

						span {
							text-overflow: ellipsis;
							overflow: hidden;
							white-space: nowrap;
							max-width: 120px;
						}
					}
				}

				th:first-child {
					div > span:first-child,
					& > span:first-child {
						font-family: Transducer-Extended;
						font-size: 16px !important;
						text-transform: uppercase;
						margin-bottom: 2px;
						position: relative;
						padding-left: 5px;
						padding-top: 5px;
						z-index: 0;

						&:before {
							content: "#";
							font-size: 16px;
							position: absolute;
							left: 3px;
							z-index: -1 !important;
							top: 0;
							color: #00ffff;
						}
					}
				}

				td,
				th {
					border-right: 1px solid #e5e5e5;
					border-bottom: 1px solid #e5e5e5;

					& > div {
						height: 34px !important;
						max-height: 34px !important;
						display: flex;
						align-items: center;
						justify-content: center;
						flex-direction: column;
					}
				}

				.score--race {
					position: relative;

					p {
						color: #888888;
						font-size: 7px;
						margin: 0;
						font-family: Transducer, sans-serif;
						font-weight: 600;
						margin-top: -2px;
						padding-inline: 2px;
					}

					.additional-score {
						background-color: #095662;
						color: white;
						position: absolute;
						height: 20px !important;
						right: -15px;
						top: 6px;
						z-index: 1;
						width: 30px !important;
						font-size: 12px;
					}
				}

				.score--olympic-bonus {
					span,
					p {
						color: #095662 !important;
					}
				}

				td {
					text-align: center;
					font-size: 13px;

					.score-wrapper {
						display: flex;
						align-items: center;
						gap: 3px;

						img {
							height: 10px;
							margin-top: -4px;
						}
					}

					div {
						width: 50px;
						max-width: 50px;
					}

					&.score {
						&--race {
							&.qualifying {
								background: #00ffff;
							}
						}

						&--season {
							background: #101010;
							color: #00ffff;
						}
					}
				}
			}
		}

		p.no-results {
			font-size: 16px;
			margin-bottom: 0 !important;
			margin-top: 0 !important;
			text-align: center;
			font-family: aktiv-grotesk;
		}
	}

	select {
		width: 248px !important;
	}

	&-grid--mobile {
		tbody {
			td {
				max-width: 52px;
				width: 52px;
			}
		}

		.name {
			gap: 24px;
			display: flex;
			flex-direction: row !important;
			align-items: center !important;
			justify-content: flex-start !important;

			span {
				&:first-child {
					padding-left: 15px !important;
					padding-bottom: 2px !important;
				}

				&:last-child {
					font-size: 14px;
				}
			}
		}

		tr:has(+ .points-breakdown-row) {
			td {
				border-bottom: none !important;
			}
		}

		td:has(div > .points-breakdown) {
			padding: 0 !important;
			border: none !important;
			background-color: #04152d !important;
		}

		div:has(> .points-breakdown) {
			height: unset !important;
			width: 100% !important;
			border: none !important;
			max-width: unset !important;

			> p {
				color: #36ffff;
			}
		}

		div:has(> .points-breakdown.show) {
			margin-top: 2px;
			margin-bottom: 6px;
			min-height: 75px !important;
		}

		.points-breakdown {
			border: 1px solid #1c5260 !important;
			background: #36ffff1a 0% 0% no-repeat padding-box;
			display: none;
			color: #00ffff;

			.score--race,
			.score--final {
				&:not(:last-child) {
					border-right: 1px solid #1c5260 !important;
				}

				div {
					width: 100%;
					max-width: unset;
					padding-bottom: 4px;
					font-family: Transducer;

					p {
						color: #00ffff !important;
						text-transform: uppercase !important;
					}
				}
			}

			p {
				font-size: 11px;
				text-transform: none;
				text-align: left;
				padding-left: 6px;
				padding-top: 4px;
			}

			td {
				border: none !important;
			}

			&.show {
				display: table;
			}
		}

		thead {
			th {
				height: 46px !important;
				font-size: 13px !important;
				padding-top: 12px !important;
				border-inline: none !important;
				border-top: 1px solid #e5e5e5;
				border-bottom: 1px solid #e5e5e5;

				div {
					border-inline: none !important;
				}
			}

			img {
				width: 80px !important;
				margin-left: 13px !important;
			}
		}

		th {
			&:first-child {
				width: auto;
			}
		}

		td,
		th {
			&:last-child {
				width: 36px;
				font-size: 18px;
			}
		}
	}

	@media (max-width: 991px) {
		.t100-points-grid--desktop {
			display: none !important;
		}
	}

	@media (min-width: 420px) {
		tbody {
			th {
				div {
					span {
						max-width: unset !important;
					}
				}
			}
		}
	}

	@media (min-width: 992px) {
		&-grid--mobile {
			display: none;
		}

		.pto-world-rankings__filters {
			padding: 32px 27px;

			form {
				.select {
					width: 167px;
				}
			}
		}

		&-grid {
			overflow-x: unset;

			ul {
				padding-block: 20px 17px;

				li {
					font-size: 13px;
				}

				img {
					height: 15px;
				}
			}

			table {
				thead {
					th {
						height: 126px;
						font-size: 10px;
						max-height: 140px;
						position: relative;
						border-bottom: 1px solid #e5e5e5;

						p {
							margin-bottom: 0;
							max-width: 120px;
							margin-left: 3px;
						}

						&:not(:first-child) {
							max-width: 69px;
							width: 69px;

							& > div {
								width: 126px;
								position: absolute;
								left: 50%;
								transform: translate(-50%, -50%);
								top: 50%;
							}
						}

						&:last-child {
							font-size: 13px;
						}

						img {
							width: 122px;
							margin-left: 22px;
						}
					}
				}

				tbody {
					th {
						font-size: 13px;
						padding-inline: 13px;
						width: auto;
					}

					// Name column
					th:first-child {
						position: relative;

						& > span:first-child {
							font-size: 20px !important;
							padding: 0;

							&::before {
								font-size: 13px;
								z-index: -1;
								left: -7px;
								top: 1px;
							}
						}

						& > span:last-child {
							position: absolute;
							left: 62px;
							display: flex;
							flex-direction: column;
							top: 50%;
							gap: 2px;
							transform: translateY(-50%);

							span:first-child {
								font-size: 11px;
								font-weight: 600;
							}
						}
					}

					td {
						font-size: 17px;

						img {
							margin-left: 4px;
							height: 16px;
							margin-bottom: 3px;
						}
					}

					td,
					th {
						height: 34px;
					}

					td,
					td > div {
						max-width: 69px;
						width: 69px;
					}
				}
			}
		}
	}
}
