.upcoming-races {
	background-color: white;
	padding-block: 24px 10px;
	padding-inline: 8px;
	text-align: center;

	h2 {
		margin-bottom: 10px;
	}

	h3 {
		margin-bottom: 6px;
	}

	& + div h2 {
		text-transform: uppercase;
		font-size: 21px;
	}

	& + div h1 {
		text-transform: uppercase;
		font-weight: 900;

		@media (min-width: 992px) {
			font-size: 34px;
		}
	}

	> div > div {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		width: 100%;
		margin-bottom: 12px;
	}

	a.js-toggle-showmore {
		cursor: pointer;
		display: inline-flex;
		align-content: center;
		justify-content: center;

		&:hover {
			color: var(--pto-red) !important;
		}
	}

	&__title {
		font: normal normal 900 18px/22px Lato;
		text-transform: uppercase;
	}

	h3 {
		font: normal normal normal 13px/16px Lato;
		letter-spacing: 0px;
		color: #000 !important;

		img {
			height: 16px;
			margin-bottom: 3px;
			margin-right: 5px;
		}
	}

	@media (min-width: 500px) {
		> div > div {
			flex-direction: row;
		}

		h2 {
			margin-bottom: 28px;
		}
	}
}

.filters-wrapper {
	margin-top: 34px;
	border: 1px solid #e0e0e0;
	padding: 25px 15px;

	.clear-filters {
		font-size: 12px;
		font-weight: 900;
		color: #989898;
		text-decoration: underline;
	}

	i {
		margin-right: 7px;
	}

	form {
		.row {
			gap: 10px 0;

			.label {
				display: inline-block;
				width: 100px;
			}

			.form-control-sm {
				border-radius: 6px;
			}

			.select-wrap {
				display: inline-block;
				position: relative;
				width: calc(100% - 100px);

				select {
					background-color: var(--select-color) !important;
					appearance: none;
					-webkit-appearance: none;
					padding-right: 24px;
					width: 100%;
				}

				&:after {
					pointer-events: none;
					display: flex;
					justify-content: center;
					align-items: center;
					position: absolute;
					width: 16px;
					height: 100%;
					top: 0;
					right: 10px;
					transition: background-color 0.3s;
					font-size: 0.75em;
					-webkit-font-smoothing: antialiased;
					font-style: normal;
					font-variant: normal;
					text-rendering: auto;
					font-family: "Font Awesome 5 Free";
					font-weight: 900;
					content: "\f078";
				}
			}
		}
	}

	@media (min-width: 768px) {
		.filters-wrapper {
			margin-top: 64px;
		}
	}
}

.upcoming-race {
	border: 1px solid #e5e5e5;
	padding: 6.5px 10.5px;
	text-align: left;
	border-radius: 3px;
	display: flex;
	flex-direction: column;
	transition: background-color 350ms;
	min-width: 140px;

	span {
		transition: color 350ms;
	}

	&:hover {
		background-color: #d10a0c;

		span {
			color: white !important;
		}
	}

	span:first-child {
		font-size: 12px !important;
		font-weight: 900 !important;
		text-transform: uppercase;
	}

	span:nth-child(2) {
		font-size: 12px !important;
		font-weight: normal !important;
		color: #888888;
	}

	&--t100 {
		span:first-child {
			font-family: Transducer !important;
			font-weight: 600 !important;
		}

		&:hover {
			background-color: #101010 !important;

			span:nth-child(2) {
				color: #00ffff !important;
			}
		}
	}
}

.latest {
	.race-list {
		.race {
			h2 {
				font-size: 1rem;

				.racename {
					font-size: inherit;
				}
			}

			h3 {
				font-size: 0.8rem;
			}

			.no-results {
				height: 90px;
				align-items: center;
				justify-content: center;
			}
		}
	}
}

.race-info {
	border: 1px solid #e0e0e0;
	padding: 2rem 1.25rem;
	padding-bottom: 1.35rem;
	display: flex;
	justify-content: space-between;
	margin-bottom: 22px;
	gap: 1rem;

	h3 {
		font-size: 18px;
		margin-bottom: 20px;
		text-transform: uppercase;
	}

	& > div > div * {
		font-size: 16px !important;
	}

	.flag-icon {
		height: unset !important;
	}

	@media (max-width: 768px) {
		flex-direction: column;
	}
}

.race-results {
	h1 {
		font-weight: 900 !important;
		font-size: 35px !important;
	}

	& > div > div {
		margin-bottom: 29px;

		& > div:nth-child(2) {
			margin-top: 2px;
		}
	}
}

.race-participants {
	padding-top: 0 !important;

	.w-100:last-child {
		@media (max-width: 769px) {
			padding-inline: 0 !important;
		}

		& > div {
			padding-inline: 17px !important;
		}
	}

	&.table {
		margin-top: 24px;
	}

	th {
		color: #000000;
		font-weight: bold;
		line-height: 12px;
		text-transform: uppercase;
		font-size: 10px !important;
		border-bottom: none !important;
		vertical-align: middle !important;

		img,
		svg {
			height: 10px;
		}

		.red {
			color: #d10b0c;
		}

		@media (min-width: 768px) {
			padding-bottom: 8px;
		}
	}

	.athlete-pic {
		border-radius: 7px !important;
	}

	@media (max-width: 769px) {
		.swim-race-ranking,
		.bike-race-ranking,
		.run-race-ranking {
			display: none;
		}
	}

	td.t100-standing,
	td.world-ranking,
	td.swim-race-ranking,
	td.bike-race-ranking,
	td.run-race-ranking {
		color: white;
		width: 58px;
		background-color: #d10b0c;
		font-size: 23px;
		text-align: center;
		position: relative;
		font-family: Avenir Next LT W04 Heavy Cn It;
		z-index: 2;
		border-bottom: 1px solid #e5e5e5;

		&::before {
			content: "#";
			color: white;
			opacity: 0.5;
			margin-left: -4px;
			font-size: 16px;
			transform: scaleX(1.4);
			position: absolute;
			font-family: "Avenir Next";
			font-weight: 700;
			z-index: -1;
		}

		@media (min-width: 768px) {
			width: 71px;
		}
	}

	td.swim-race-ranking,
	td.bike-race-ranking,
	td.run-race-ranking {
		background-color: white;
		border: 1px solid #e5e5e5;
		color: black;

		&::before {
			opacity: 1;
		}
	}

	td.swim-race-ranking {
		&::before {
			color: #456ae5;
		}
	}

	td.bike-race-ranking {
		&::before {
			color: #009d5b;
		}
	}

	td.run-race-ranking {
		&::before {
			color: #d10b0c;
		}
	}

	td.t100-standing {
		background-color: #101010;

		&::before {
			color: #00ffff;
			opacity: 1;
		}
	}

	.run-race-ranking {
		position: relative;
	}

	.race-rankings {
		padding-left: 8px;
		padding-right: 12px;
		width: 72px;
		position: relative;

		@media (min-width: 768px) {
			display: none;
		}
	}

	.tooltip-button {
		padding-inline: 0;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);

		img {
			height: 13px;
			width: 13px;
		}
	}

	.tooltip {
		background-color: black;
		border: none;
		color: white;
		width: 174px;
		right: 0;

		.red {
			position: absolute;
		}

		p {
			&::first-letter {
				margin-left: 6px;
			}
		}
	}

	td.race-rankings {
		border: 1px solid #e5e5e5;

		div {
			display: flex;
			flex-direction: column;

			& > span {
				text-transform: uppercase;
				font-family: Lato, sans-serif;
				font-size: 10px;
				line-height: 11px;
				font-weight: bold;

				&:first-child {
					b::before {
						color: #456ae5;
					}
				}

				&:nth-child(2) {
					b::before {
						color: #009d5b;
					}
				}

				&:last-child {
					b::before {
						color: #d10b0c;
					}
				}

				b {
					font-family: Avenir Next LT W04 Heavy Cn It;
					position: relative;

					&::before {
						content: "#";
						color: white;
						margin-top: -1px;
						margin-left: -2px;
						font-size: 6px;
						transform: scaleX(1.4);
						position: absolute;
						font-family: Avenir Next LT W04 Heavy Cn It;
					}
				}
			}
		}
	}

	.athlete {
		width: auto;
	}

	.name {
		display: flex;
		align-items: center;
		line-height: 15px;
		font-size: 13px !important;

		.flag-icon {
			height: 14px;
			min-width: 20px;
			margin-right: 9px !important;
		}
	}

	h1 {
		font-size: 35px !important;
		margin-top: 36px;
		margin-bottom: 4px !important;

		svg {
			height: 26px;
			margin-right: 6px;
			margin-bottom: 3px;
		}
	}

	h1 + h3,
	.h3 {
		font-size: 19px;
		font-weight: 700;
	}

	.race-participants__buttons + .link-anchor {
		margin-top: 24px;

		&:after {
			margin-top: 3px;
		}
	}

	h3 {
		text-transform: uppercase;
		font-size: 18px;
		font-weight: 900;
		letter-spacing: 0;
		margin-bottom: 13px;
	}

	&__info {
		border: 1px solid #e0e0e0;
		padding: 2rem 1.25rem;
		padding-bottom: 1.35rem;
		display: flex;
		justify-content: space-between;
		margin-bottom: 22px;
		gap: 1rem;

		@media (max-width: 768px) {
			flex-direction: column;
		}
	}

	&__how-to-watch {
		margin-right: 22px;

		@media (min-width: 768px) {
			max-width: 50%;
		}

		@media (max-width: 769px) {
			margin-top: 20px;
		}
	}

	&__buttons {
		display: flex;
		gap: 0.75rem;
		margin-top: 18px;
		width: 100%;
		flex-wrap: wrap;

		@media (max-width: 768px) {
			flex-direction: column;
		}

		a {
			--background-color: #d10b0c;
			--color: white;
			background-color: var(--background-color);
			border: 2px solid var(--background-color);
			color: var(--color);
			width: max-content;
			font-size: 12px;
			font-weight: 900;
			padding-block: 13px 15px;
			padding-inline: 12px;
			text-transform: uppercase;
			border-radius: 3px;
			transition: background-color 350ms, color 350ms;

			svg {
				width: 16px;
				fill: var(--color);
				margin-bottom: 3px;
				margin-left: 1rem;
				transition: fill 350ms;
			}

			&:hover {
				background-color: transparent;
				color: var(--background-color);

				svg {
					fill: var(--background-color);
				}
			}
		}
	}

	td {
		.hide-mobile {
			display: none;
		}

		@media (min-width: 992px) {
			.hide-mobile {
				display: inline-block;
			}
			.hide-desktop {
				display: none;
			}
		}
	}

	&--t100 {
		::selection {
			background-color: #00ffff;
			color: white;
		}

		.name:has(.wildcard-label) {
			flex-wrap: wrap;

			@media (min-width: 992px) {
				width: 100%;
				flex-wrap: nowrap;
			}
		}

		.wildcard-label {
			font-family: Transducer-Extended, sans-serif;
			text-transform: uppercase;
			font-size: 9px;
			display: block;
			margin-left: 6px;
			font-weight: 700;
			color: #fa1cb8;

			@media (min-width: 992px) {
				z-index: 0;
				font-size: 10px;
				text-align: center;
				height: 25px;
				display: flex;
				align-items: center;
				margin-left: auto;
				margin-right: 20px;
				position: relative;

				&::before {
					content: "";
					display: block;
					width: 100px;
					height: 24px;
					z-index: -1;
					border: 1px solid #fa1cb8;
					transform: skewX(-30deg) translate(-50%, -50%);
					position: absolute;
					left: 42%;
					top: 52%;
				}
			}
		}

		h1 {
			font-family: Transducer-Extended, sans-serif !important;
			text-transform: uppercase;
			color: white;
			font-size: 18px !important;
			font-weight: 900 !important;
			margin-top: 74px;
		}

		h2 {
			color: white;
			margin-top: 4px;
			font-size: 16px !important;
			font-weight: normal !important;
			font-family: aktiv-grotesk !important;
		}

		h3 {
			font-size: 18px;
			margin-bottom: 11px;
			font-family: Transducer !important;

			&:last-of-type {
				margin-bottom: 14px;
			}
		}

		.h3 {
			font-family: aktiv-grotesk !important;
		}

		.race-participants__info {
			color: white;
			font-size: 14px;
			padding: 17px 13px;
			font-family: aktiv-grotesk !important;

			.ml-1 {
				color: white !important;
			}
		}

		.participants-header {
			margin-bottom: 38px !important;
		}

		.participants-table:last-child {
			margin-top: 32px !important;
		}

		.participants-top {
			padding-bottom: 21px;
		}

		.h3:first-child {
			font-size: 19px;
			font-weight: 600 !important;
			text-transform: uppercase;
			font-family: Transducer;
		}

		.tooltip-button {
			img {
				width: 14px !important;
				height: 14px !important;
			}
		}

		th {
			font-family: Transducer;
			font-weight: 600 !important;
		}

		.tooltip {
			background-color: #04152d !important;

			.red {
				color: #00ffff !important;
			}
		}

		td {
			.name {
				font-family: Transducer;
			}
		}

		.t100-standing,
		.world-ranking {
			font-family: Transducer !important;
		}

		td.t100-standing,
		td.world-ranking,
		td.swim-race-ranking,
		td.bike-race-ranking,
		td.run-race-ranking {
			font-family: Transducer !important;
			font-weight: 700 !important;
		}

		th {
			.red {
				color: #00ffff !important;
			}
		}

		@media (max-width: 400px) {
			.athlete-pic {
				display: none !important;
			}

			.name {
				margin-left: 0 !important;
			}
		}

		.race-participants__buttons {
			a {
				border: 1px solid #e5e5e5;
				border-radius: 3px;
				font-size: 12px;
				font-family: Transducer;
				min-width: 188px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				svg {
					margin-bottom: 0 !important;
				}

				&:hover {
					color: white;

					svg {
						fill: white;
					}
				}
			}

			+ a {
				color: white;
				margin-top: 15px !important;

				&::after {
					color: white;
				}
			}
		}

		@media (min-width: 992px) {
			td.t100-standing,
			td.world-ranking,
			td.swim-race-ranking,
			td.bike-race-ranking,
			td.run-race-ranking {
				width: 77px !important;

				&::before {
					top: 15px;
					margin-left: -8px;
				}
			}

			td.swim-race-ranking::before {
				color: #d3fe35;
			}

			td.bike-race-ranking::before {
				color: #fb5800;
			}

			td.run-race-ranking::before {
				color: #fa1cb8;
			}

			.name {
				margin-left: 16px !important;
			}

			.name .headline {
				font-family: Transducer-Extended;
				font-size: 14px;

				&:hover {
					color: #00ffff !important;
				}
			}

			h1 {
				margin-top: 140px;
				font-size: 22px !important;
			}

			h2 {
				font-size: 17px !important;
				margin-top: 14px;
			}

			h3 {
				margin-bottom: 22px;
			}

			.h3:not(h2):first-child {
				font-family: Transducer-Extended !important;
				font-size: 17px !important;
				font-weight: 700 !important;
			}

			.h3:not(h2):last-child {
				font-size: 26px !important;
			}

			.participants-table > div:first-child {
				align-items: center;
			}

			.race-participants__buttons {
				flex-wrap: nowrap !important;

				a {
					transition: all 350ms;

					&:hover {
						border-color: #36ffff;
						background-color: #36ffff;
						color: white;

						svg {
							fill: white !important;
						}
					}
				}

				+ .link-anchor {
					&::after {
						transition: all 350ms;
					}

					&:hover {
						color: #36ffff !important;

						&::after {
							color: #36ffff !important;
						}
					}
				}
			}

			.participants-header {
				margin-bottom: 50px !important;
			}

			.race-participants__info {
				padding: 25px 22px;
			}
		}

		a {
			--background-color: #02162d !important;
			--color: #00ffff !important;
		}

		.athlete-pic {
			background: url("./assets/t100-athlete-bg.png") !important;
		}
	}
}

.main:has(.race-participants--t100) {
	padding-bottom: 42px;
	background-color: #04152d;
	background-size: 100%;
	background-repeat: no-repeat;
	background-image: url("./assets/t100-home-page-background.png");

	@media (min-width: 992px) {
		padding-bottom: 72px;
	}
}
