.pto-world-rankings {
  h2 {
    text-transform: uppercase;
    font-size: 21px;
  }
}

.rankings {
	.details {
   	p.explainer--2022-recalculated {
      	text-align: center;
   	}
	}
}

h1.pto-world-rankings {
	font-size: 41px;
	font-family: Lato;
	font-weight: 900;
	white-space: nowrap;
	color: black !important;

	span {
		margin-left: 12px; 
	}
}

.rankings a > h3 {
	font-size: 20px;
	display: inline-block;
}
