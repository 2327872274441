.selector {
	position: fixed;
	display: flex;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0,.5);
	z-index: 600;
	justify-content: center;
	align-items: start;

	.selector-container
	{
		width: 100%;
		max-width: 400px;
		background-color: #222;
		max-height: calc(100% - 120px);
		padding: 16px 16px 0 16px;
		display: flex;
		flex-direction: column;
		border-radius: 16px;
		margin: 60px 0;

		@media (max-width: 768px) {
			margin: 0;
			border-radius: 0;
			height: 100%;
			max-height: unset;
		}


		.back {
			display: inline-flex;
			justify-content: center;
			align-items: center;
			margin-right: 16px;
			cursor: pointer;
			width: 45px;
			height: 45px;
			border-radius: 50px;

			&:hover {
				background-color: #ddd;			
			}

			&:before {
				line-height: 28px;
				font-size: 28px;
				color: var(--pto-red);

				-webkit-font-smoothing: antialiased;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				//line-height: 1.5em;
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				content: "\f060";

				/*content: "arrow_back";
				font-family: 'Material Icons Round';
				font-weight: normal;
				font-style: normal;
				letter-spacing: normal;
				text-transform: none;
				white-space: nowrap;
				word-wrap: normal;
				direction: ltr;
				-webkit-font-feature-settings: 'liga';
				-webkit-font-smoothing: antialiased;*/		
			}

		}

		.selector-title {
			display: flex;
			align-items: center;
			//text-align: center;
			flex-shrink: 0;
			margin-bottom: .25rem;
		}

		input {
			margin-bottom: 8px;
			flex-shrink: 0;
		}

		ul {
			margin: 0;
			padding: 0;
			flex-grow: 1;
			overflow-y: auto;
			position: relative;
			li {
				padding: 8px 32px;
				margin: 0;
				display: flex;
				cursor: pointer;
				justify-content: center;
				align-items: center;

				justify-content: space-between;

				background-color: #323334;
				margin: 8px;
				border-radius: 8px;

				&:active {
					background-color: #424344;
				}
				&:focus {
					background-color: #424344;
				}
				&:focus-within {
					background-color: #424344;
				}

				transition: background-color 250ms;
				&:hover {
					box-shadow: 0px 0px 10px rgba(0,0,0,.25);
					background-color: #303030;
				}
				padding: 8px 16px;
				margin: 8px 0;

				&[selected] { background-color: var(--pto-red); }
			}
		}
	}
}

.modal-select {
	cursor: pointer;	
}

html.bright {
	.selector {
		.selector-container {
			background-color: white;

			ul { 
				//color: white; 
				li {
					background-color: #ddd;
					&:hover {
						box-shadow: unset;
						background-color: #ccc;
					}
					&[selected] { background-color: var(--pto-red); color: white; }
				}
			}
		}
	}
}