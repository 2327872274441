.custom-scrollbar
{
	&.scroll-x { 
		//margin-bottom: -10px !important; 
		scroll-behavior: smooth;
	}
	
	&::-webkit-scrollbar {
		width: 10px;
		height: 0px;
	}
	&::-webkit-scrollbar-button { width: 0; height: 0; }
	&::-webkit-scrollbar-thumb {
		background: transparent;
		border: 0px none #ffffff;
		border-radius: 50px;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: #303030;
	}
	&::-webkit-scrollbar-thumb:active {
		background: #303030;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
		border: 0;
	}
	&::-webkit-scrollbar-track:hover { background: transparent; }
	&::-webkit-scrollbar-track:active { background: transparent; }
	&::-webkit-scrollbar-corner { background: transparent; }  

	&:hover {
		&::-webkit-scrollbar-thumb {
			background: rgba(255,255,255,0.1);
		}
	}

}

.scroll-anchors {
		width: 100%;

		position: relative;
		.scroll-anchor-left, .scroll-anchor-right {
			position: absolute;
			font-family: "Font Awesome 5 Free";	
			font-weight: 900;
			font-style: normal;
			font-size: 30px;
			color: var(--pto-red);
			color: white;
			display: flex;
			width: 45px;
			height: 45px;
			top: 50%;
			transform: translateY(-50%);
			align-items: center;
			justify-content: center;
			//text-shadow: 0 0 16px black;
			cursor: pointer;
			z-index: 202;
			display: none;
		}
		
		.scroll-anchor-left {
			border-radius: 50px;
			left: 31px;
			transform: translateY(-50%) translateX(-50%);
			background: radial-gradient(black, transparent 70%);
		}
		.scroll-anchor-right {
			border-radius: 50px;
			right: 31px;
			transform: translateY(-50%) translateX(50%);
			background: radial-gradient(black, transparent 70%);
		}

		&.show-right-anchor
		{
			.scroll-anchor-left {
				display: flex;
			}

			@media (hover: none) {
				.scroll-anchor-left {
					display: none;
				}
			}

		}

		&.show-left-anchor
		{
			.scroll-anchor-right {
				display: flex;
			}
			@media (hover: none) {
				.scroll-anchor-right {
					display: none;
				}
			}
		}
}



