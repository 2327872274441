
.t100-logo {
	--color: #02162d;
	display: inline-flex !important;
	align-items: center;
	max-width: unset !important;

	@media (max-width: 768px) {
		margin-top: 4px;

		svg,
		img {
			width: 50px;
			margin-right: 5px;
			margin-left: 0 !important;

			g {
				transform: scale(1.37);
			}
		}

		span {
			font-size: 9px !important;
		}
	}

	svg,
	img {
		width: 106px;
		margin-right: 5px;
		margin-left: -10px;

		g {
			transform: scale(1.6);
		}
	}

	path, rect {
		fill: var(--color);
	}

	span {
		font-family: 'Transducer', sans-serif;
		min-width: fit-content;
		font-weight: 600;
		color: var(--color);
		font-size: 12px !important;
	}
}
