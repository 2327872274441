.pro-events-directory {
  .header-wrapper {
    margin-top: 15px;

    img {
      height: 26px;
      filter: invert(1);
      transform: translateY(-3px);
    }

    h1 {
      margin-top: 10px;
      color: #000000;
      font-size: 25px;
      font-weight: 900;
      white-space: nowrap;
      text-transform: uppercase;
    }

    p {
      margin-top: 10px;
      font-size: 14px;

      a {
        text-decoration: underline;
      }
    }
  }

  .filters-wrapper {
    margin-top: 34px;
    border: 1px solid #E0E0E0;
    padding: 25px 15px;

    .clear-filters {
      font-size: 12px;
      font-weight: 900;
      color: #989898;
      text-decoration: underline;
    }

    i {
      margin-right: 7px;
    }

    form {
      .row {
        gap: 10px 0;

        .label {
          display: inline-block;
          width: 100px;
        }

        .form-control-sm {
          border-radius: 6px;
        }

        .select-wrap {
          display: inline-block;
          position: relative;
          width: calc(100% - 100px);

          select {
            background-color: var(--select-color)!important;
            appearance: none;
            -webkit-appearance: none;
            padding-right: 24px;
            width: 100%;
          }

          &:after {
            pointer-events: none;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            width: 16px;
            height: 100%;
            top: 0;
            right: 10px;
            transition: background-color .3s;
            font-size: .75em;
            -webkit-font-smoothing: antialiased;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            content: "\f078";
          }
        }
      }
    }
  }

  .events-wrapper {
    margin-top: 20px;

    .sortable-head {
      padding: 0 30px 0 12px;
      margin-bottom: 5px;

      .row {
        gap: 5px 0;

        .sortable-control {
          cursor: pointer;

          i {
            opacity: 0.65;
          }

          &:hover {
            i {
              opacity: 1;
            }
          }

          &.active {
            i {
              color: #D10B0C;
            }
          }
        }
      }
    }

    .event {
      & + .event {
        margin-top: 0.25rem;
      }

      .card {
        border: 1px solid var(--border-color);
        background-color: white;
        padding: 8px 30px 8px 12px;
        position: relative;
        font-size: 12px;

        .row {
          gap: 5px 0;

          .col-name {
            font-weight: 900;
            text-transform: uppercase;

            .flag-icon {
              display: inline-block;
              width: 20px;
              height: 12px;
              margin-right: 5px;
            }
          }

          .col-brand {
            svg {
              width: 53px;
            }
          }
        }

        .expand-event-details {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 10px;
          font-size: 19px;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .details {
        border-color: #000000;
        background-color: #000000;
        color: white;
        max-height: 0;
        transition: max-height .25s ease-in-out;
        overflow: hidden;

        .inner {
          transition: opacity .25s ease-in-out;
          visibility: hidden;
          opacity: 0;
          padding: 20px 12px;

          .row {
            gap: 20px 0;
          }

          p {
            margin: 0;
            font-size: 12px;

            a {
              color: white;
              text-decoration: underline;

              &:hover {
                color: #D10B0C;
              }
            }

            & + p {
              margin-top: 5px;
            }
          }

          .btn-primary {
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 900;
            box-shadow: none;

            &:hover {
              background-color: #D10B0CDD !important;
            }
          }
        }

        &.show {
          max-height: 500px;

          .inner {
            visibility: visible;
            opacity: 1;
          }
        }
      }

      &.brand-t100 {
        .details {
          border-color: #02162D;
          background-color: #02162D;

          p {
            a {
              &:hover {
                color: #00FFFF;
              }
            }
          }

          .inner {
            .btn-primary {
              background-color: #00FFFF;
              color: #02162D;
              border-color: #00FFFF;
              transition: background-color .3s ease-in-out;

              &:hover,
              &:active,
              &:focus {
                background-color: #00FFFFDD !important;
                color: #02162D !important;
                border-color: #00FFFF !important;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    .header-wrapper {
      margin-top: 35px;

      img {
        height: 38px;
      }

      h1 {
        font-size: 32px;
      }
    }

    .filters-wrapper {
      margin-top: 64px;
    }

    .events-wrapper {
      margin-top: 40px;

      .event {
        .card {
          padding-top: 8px;
          padding-bottom: 8px;

          .row {
            align-items: center;
            min-height: 36px;

            .col-brand {
              svg {
                width: 65px;
              }
            }
          }
        }

        .details {
          .inner {
            p {
              font-size: 14px;
            }
          }
        }

        &.has-country {
          .card {
            .row {
              .col-name {
                position: relative;
                padding-left: 25px;
                line-height: .8rem;

                .flag-icon {
                  position: absolute;
                  top: 50%;
                  left: 0;
                  transform: translateY(-50%);
                }
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 992px) {
    .events-wrapper {
      .event {
        .card {
          padding-top: 17px;
          padding-bottom: 17px;
          font-size: 14px;

          .row {
            .col-name {
              font-size: 15px;

              .flag-icon {
                transform: translateY(2px);
              }
            }

            .col-brand {
              svg {
                width: 74px;
              }
            }
          }
        }

        &.has-country {
          .card {
            .row {
              .col-name {
                padding-left: 30px;
                line-height: .9rem;
              }
            }
          }
        }
      }
    }
  }
}