.tooltip-button {
	background-color: transparent;
	border: none;
	outline: none;

	img {
		width: 16px;
		max-height: unset !important;
		margin-bottom: 0;
	}

	&:active,
	&:focus {
		outline: none;
	}
}

.tooltip {
	&:not(.tooltip.hidden) {
		opacity: 1 !important;
		display: block;
	}

	z-index: 3 !important;
	background-color: white;
	border: 1px solid #888;
	border-radius: 5px;
	color: #000000;
	font-size: 12px;
	padding: 7px 11px;
	font-family: Lato, sans-serif;

	p {
		padding: 0;
		margin: 0;
	}
}
