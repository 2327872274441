.brands
{
	display: flex;
	margin: 0 auto;
	width: 100%;
	flex-direction: column;
	align-items: center;
	table {
		width: 100%;
		max-width: 600px;
		text-align: center;
		img { 
			height: 40px;
			filter: invert(.773); 
		}

		td {
			padding: 16px 6px; 
			font-size: 1.5rem; 
		}

		.logo { 
			display: flex; 
			justify-content: center;
			align-items: center;
		}
	}
}
