input[type="range"] {
  --webkitProgressPercent: 0%;
}

$thumb-color: #D10B0C;
$bar-color: #444;

input[type=range] {
  width: 100%;
  margin: 4px 0;
  background-color: transparent;
  -webkit-appearance: none;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  background: $bar-color;
  border: 0;
  width: 100%;
  height: 8px;
  cursor: pointer;
  background-image: linear-gradient(90deg, $thumb-color var(--webkitProgressPercent), #262626 var(--webkitProgressPercent));
  border-radius: 16px;
}
input[type=range]::-webkit-slider-thumb {
  margin-top: -8px;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background: $thumb-color;
  border: 0;
  cursor: pointer;
  -webkit-appearance: none;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  //background: $bar-color;
}
input[type=range]::-moz-range-track {
  background: $bar-color;
  border: 0;
  width: 100%;
  height: 8px;
  cursor: pointer;
  background-image: linear-gradient(90deg, $thumb-color var(--webkitProgressPercent), #262626 var(--webkitProgressPercent));
  border-radius: 16px;
}
input[type=range]::-moz-range-thumb {
  width: 24px;
  height: 24px;
  background: $thumb-color;
  border: 0;
  border-radius: 24px;
  cursor: pointer;
}
input[type=range]::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 5px 0;
  color: transparent;
  width: 100%;
  height: 8px;
  cursor: pointer;
  background-image: linear-gradient(90deg, $thumb-color var(--webkitProgressPercent), #262626 var(--webkitProgressPercent));
  border-radius: 16px;
}
input[type=range]::-ms-fill-lower {
  background: #dd1100;
  border: 0;
}
input[type=range]::-ms-fill-upper {
  background: #dd1100;
  border: 0;
}
input[type=range]::-ms-thumb {
  width: 24px;
  height: 24px;
  background: $thumb-color;
  border: 0;
  border-radius: 24px;
  cursor: pointer;
  margin-top: 0px;
}
input[type=range]:focus::-ms-fill-lower {
  background: #dd1100;
}
input[type=range]:focus::-ms-fill-upper {
  background: #dd1100;
}

/*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
how to remove the virtical space around the range input in IE*/
@supports (-ms-ime-align:auto) {
  input[type=range] {
    margin: 0;
  }
}
